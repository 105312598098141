import React, { useRef } from "react";
import album_01 from "../../../../assets/icon/album_01.svg";
import { Menu } from "primereact/menu";

export const CardContent = ({
  isList,
  card,
  onFavoriteClick,
  onOtherClick,
}) => {
  const menuRight = useRef(null);
  const handleFavoriteClick = () => {
    onFavoriteClick(card);
  };
  const handleOtherClick = (text) => {
    onOtherClick(card, text);
  };

  let items = [
    {
      label: "Download",
      icon: "pi pi-fw pi-download",
      command: (e) => {
        handleOtherClick("download");
      },
    },
    {
      label: "Sysn to cloud",
      icon: "pi pi-fw pi-cloud-upload",
      command: (e) => {
        handleOtherClick("sysn");
      },
    },
    {
      label: "Move to trash",
      icon: "pi pi-fw pi-trash",
      command: (e) => {
        handleOtherClick("remove");
      },
    },
  ];

  return (
    <>
      <div
        className={["card-header-wrapper", isList ? "isList" : ""].join(" ")}
      >
        {isList === true && (
          <div className="img-isList">
            <img src={album_01} alt={card.name} />
          </div>
        )}
        {/* {card.isSharing === false && (
          <div className="img-group">
            <img className="folder-img" alt="" src={folder}></img>
          </div>
        )}
        {card.isSharing === true && (
          <div className="img-group">
            <img className="folder-img" alt="" src={folder}></img>
            <img className="share-img" alt="" src={share}></img>
          </div>
        )} */}

        <span className="card-name main-darker-02-color">{card.name}</span>
        <i
          className={["pi", card.isFavorite ? "pi-star-fill" : "pi-star"].join(
            " "
          )}
          onClick={handleFavoriteClick}
        ></i>
        <Menu
          model={items}
          popup
          ref={menuRight}
          id="popup_menu_right"
          popupAlignment="right"
        />
        <i
          className="pi pi-ellipsis-v"
          onClick={(event) => menuRight.current.toggle(event)}
        ></i>
      </div>
      {isList === false && (
        <div className="img-wrapper">
          <img src={card.url} alt={card.name} />
        </div>
      )}
    </>
  );
};
