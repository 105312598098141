import React, { useRef } from "react";
import folder from "../../../../assets/icon/folder_01.svg";
import share from "../../../../assets/icon/share_circle_01.svg";
import { Menu } from "primereact/menu";

export const CardContent = ({ card, onFavoriteClick, onOtherClick }) => {
  const menuRight = useRef(null);
  const handleFavoriteClick = () => {
    onFavoriteClick(card);
  };
  const handleOtherClick = (text) => {
    onOtherClick(card, text);
  };

  let items = [
    {
      label: "Download",
      icon: "pi pi-fw pi-download",
      command: (e) => {
        handleOtherClick("download");
      },
    },
    {
      label: "Sysn to cloud",
      icon: "pi pi-fw pi-cloud-upload",
      command: (e) => {
        handleOtherClick("sysn");
      },
    },
    {
      label: "Move to trash",
      icon: "pi pi-fw pi-trash",
      command: (e) => {
        handleOtherClick("remove");
      },
    },
  ];

  return (
    <>
      <div className="img-group">
        <img className="folder-img" alt="" src={folder}></img>
      </div>

      <span className="card-name main-darker-02-color">{card.name}</span>
      <i
        className={["pi", card.isFavorite ? "pi-star-fill" : "pi-star"].join(
          " "
        )}
        onClick={handleFavoriteClick}
      ></i>
      <Menu
        model={items}
        popup
        ref={menuRight}
        id="popup_menu_right"
        popupAlignment="right"
      />
      <i
        className="pi pi-ellipsis-v"
        onClick={(event) => menuRight.current.toggle(event)}
      ></i>
    </>
  );
};
