import "./App.css";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
// Import Prime react
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";

import { Login } from "./pages/login/Login";
import { PimaHome } from "./pages/home/PimaHome";
import { Register } from "./pages/register/Register";
import { Forget } from "./pages/forget-password/Forget";
import { Project } from "./pages/home/project/Project";
import { SubProject } from "./pages/home/sub-project/SubProject";
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login></Login>}></Route>
        <Route path="/register" element={<Register></Register>}></Route>
        <Route path="/Forget" element={<Forget></Forget>}></Route>

        <Route path="/pimahome/*" element={<PimaHome />}>
          <Route index element={<Project />} />
          <Route path=":id" element={<SubProject />} />
        </Route>
      </Routes>
    </Router>
  );
}
export default App;
