import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./SearchBar.scss";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import view_folder_image from "../../../assets/icon/view_folder_01.svg";
import view_list_image from "../../../assets/icon/view_list_01.svg";
import filter_image from "../../../assets/icon/filter_01.svg";
import other_02_image from "../../../assets/icon/other_02.svg";

export const SearchBar = ({ text, onSearchChange, setIsList }) => {
  const [searchText, setSearchText] = useState("");
  const [type, setType] = useState(false);
  const onSearchChangeHandler = () => {
    onSearchChange(searchText);
  };

  const isListHandler = () => {
    setType(!type);
    setIsList(!type);
  };

  useEffect(() => {
    setSearchText(text);
  }, [text]);

  return (
    <div className="seach-bar-wrapper">
      <span className="seach-bar p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          placeholder="Search"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
      </span>

      <div className="btn-groub-wrapper">
        <Button
          label="Search"
          type="submit"
          className="seach-bar-btn"
          onClick={onSearchChangeHandler}
        />

        <div className="icon-groun-wrapper">
          <img
            className="cursor-pointer"
            src={type ? view_list_image : view_folder_image}
            alt=""
            onClick={isListHandler}
          />
          <img src={filter_image} alt="" />
          <img src={other_02_image} alt="" />
        </div>
      </div>
    </div>
  );
};

SearchBar.propTypes = {};

SearchBar.defaultProps = {};
