import React, { useState, useEffect, useRef } from "react";
import { useOutletContext } from "react-router-dom";
import "./SubProject.scss";
import { Card } from "../../../components/home/content/image/Card";
import { SubProjectCard } from "../../../components/home/content/sub-project/Card";
import { Galleria } from "primereact/galleria";
import { v4 as uuidv4 } from "uuid";

export const SubProject = ({}) => {
  let filteredCards = useOutletContext().filteredCards;
  let isList = useOutletContext().isList;
  let selectedCards = useOutletContext().selectedCards;
  let handleItemSelection = useOutletContext().handleItemSelection;
  let clearItemSelection = useOutletContext().clearItemSelection;
  let handleFavoriteClick = useOutletContext().handleFavoriteClick;
  let handleOtherClick = useOutletContext().handleOtherClick;
  let handleDoubleClick = useOutletContext().handleDoubleClick;

  const [activeIndex, setActiveIndex] = useState(0);
  const galleria = useRef(null);

  // const itemTemplate = (item) => {
  //   return (
  //     // <img
  //     //   src={item.url}
  //     //   alt={item.name}
  //     //   style={{ width: "100%", display: "block" }}
  //     // />
  //   );
  // };
  const downloadImage = () => {
    const image = filteredCards[activeIndex];
    const link = document.createElement("a");
    link.href = image.url;
    link.download = image.url;
    link.click();
  };

  const itemTemplate = (item) => {
    return (
      <div>
        <img src={item.url} alt={item.alt} />
        <button onClick={downloadImage}>Download</button>
      </div>
    );
  };

  return (
    <div
      className={["folder-container", isList ? "list" : ""].join(" ")}
      key={"sub-project"}
    >
      <div className={["cards-wrapper", isList ? "list" : ""].join(" ")}>
        {filteredCards?.map((card, i) => {
          switch (card.type) {
            case "folder":
              return (
                <SubProjectCard
                  card={card}
                  key={"card-folder-" + card.id}
                  id={card.id}
                  index={i}
                  selectedCards={selectedCards}
                  onSelectionChange={handleItemSelection}
                  clearItemSelection={clearItemSelection}
                  isSelected={selectedCards.includes(card)}
                  isList={isList}
                  onFavoriteClick={handleFavoriteClick}
                  onOtherClick={handleOtherClick}
                  onDoubleClick={handleDoubleClick}
                />
              );

            default:
              return (
                <div
                  key={"card-image" + card.id}
                  style={isList ? { width: "100%" } : {}}
                >
                  <Galleria
                    ref={galleria}
                    value={filteredCards}
                    activeIndex={activeIndex}
                    onItemChange={(e) => setActiveIndex(e.index)}
                    circular
                    fullScreen
                    showItemNavigators
                    showThumbnails={false}
                    item={itemTemplate}
                  />
                  <Card
                    card={card}
                    id={card.id}
                    index={i}
                    selectedCards={selectedCards}
                    onSelectionChange={handleItemSelection}
                    clearItemSelection={clearItemSelection}
                    isSelected={selectedCards.includes(card)}
                    isList={isList}
                    onFavoriteClick={handleFavoriteClick}
                    onOtherClick={handleOtherClick}
                    onDoubleClick={handleDoubleClick}
                    setActiveIndex={setActiveIndex}
                    galleria={galleria}
                  />
                </div>
              );
          }
        })}
      </div>
    </div>
  );

  // return (
  //   <div>
  //     {filteredCards?.map((img, index) => (
  //       <div key={index}>
  //         <p>Image Name: {img.name}</p>
  //         <img src={img.url} alt={img.name} />
  //       </div>
  //     ))}
  //   </div>
  // );
};

SubProject.propTypes = {};

SubProject.defaultProps = {};
