import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import "./Forget.scss";
import { Avatar } from "primereact/avatar";
import { Controller, useForm } from "react-hook-form";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";

export const Forget = ({}) => {
  const navigate = useNavigate();
  const toast = useRef(null);

  const show = () => {
    toast.current.show({
      severity: "success",
      summary: "Send Email Success",
      // detail: getValues("email") + getValues("password"),
    });
  };

  const defaultValues = {
    email: "",
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    getValues,
    reset,
  } = useForm({ defaultValues });

  const [loading, setLoading] = useState(false);
  const onSubmit = (data) => {
    setLoading(true);

    const allFieldsFilled = Object.values(data).every((value) => !!value);

    if (allFieldsFilled) {
      console.log(data);
      show();
      setTimeout(() => {
        setLoading(false);
        navigate("/");
        reset();
      }, 2000);
    } else {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "All fields must be filled.",
      });
    }
  };

  const getFormErrorMessage = (name) => {
    return errors[name] ? (
      <small className="p-error">{errors[name].message}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  const handleBackClick = () => {
    navigate("/");
  };

  return (
    <div className="register-container" key={"forget"}>
      <div className="register-wrapper">
        <div className="logo-wrapper">
          <Avatar icon="pi pi-box" size="xlarge" shape="circle" />
          <span className="header-text main-darker-01-color">Forget</span>
        </div>
        <div className="input-wrapper">
          <form onSubmit={handleSubmit(onSubmit)} className="flex flex-column ">
            <Toast ref={toast} />
            <Controller
              name="email"
              control={control}
              rules={{ required: "Email is required." }}
              render={({ field, fieldState }) => (
                <>
                  <label
                    htmlFor={field.name}
                    className="header-input-text main-darker-01-color"
                  >
                    Email Address
                  </label>

                  <InputText
                    id={field.name}
                    {...field}
                    className={classNames({ "p-invalid": fieldState.error })}
                    onChange={(e) => field.onChange(e.target.value)}
                  />

                  {getFormErrorMessage(field.name)}
                </>
              )}
            />

            <Button
              label="Submit"
              type="submit"
              className="register-btn"
              loading={loading}
            />
          </form>
          <Button
            label="Back"
            className="back-btn"
            outlined
            onClick={handleBackClick}
          />
        </div>
      </div>
    </div>
  );
};

Forget.propTypes = {};

Forget.defaultProps = {};
