import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import "./Login.scss";
import { Avatar } from "primereact/avatar";
import { Controller, useForm } from "react-hook-form";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Checkbox } from "primereact/checkbox";
import { Divider } from "primereact/divider";

import google_color_image from "../../assets/icon/google_01.svg";
import google_dark_image from "../../assets/icon/google_02.svg";
import facebook_color_image from "../../assets/icon/facebook_01.svg";
import facebook_dark_image from "../../assets/icon/facebook_02.svg";

export const Login = ({}) => {
  const navigate = useNavigate();
  const toast = useRef(null);
  const [checked, setChecked] = useState(false);

  const show = () => {
    toast.current.show({
      severity: "success",
      summary: "Form Submitted",
      detail: getValues("email") + getValues("password"),
    });
    navigate("/pimahome");
  };

  const defaultValues = {
    email: "",
    password: "",
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    getValues,
    reset,
  } = useForm({ defaultValues });

  const onSubmit = (data) => {
    data.email && data.password && show();

    reset();
  };

  const getFormErrorMessage = (name) => {
    return errors[name] ? (
      <small className="p-error">{errors[name].message}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  const handleNavClick = (path) => {
    navigate(path);
  };

  return (
    <div className="login-container" key={"login"}>
      <div className="login-wrapper">
        <div className="logo-wrapper">
          <Avatar icon="pi pi-box" size="xlarge" shape="circle" />
          <span className="header-text main-darker-01-color">PiMa</span>
        </div>
        <div className="input-wrapper">
          <form onSubmit={handleSubmit(onSubmit)} className="flex flex-column ">
            <Toast ref={toast} />
            <Controller
              name="email"
              control={control}
              rules={{ required: "Email is required." }}
              render={({ field, fieldState }) => (
                <>
                  <label
                    htmlFor={field.name}
                    className="header-input-text main-darker-01-color"
                  >
                    Email Address
                  </label>
                  <span className="p-float-label">
                    <InputText
                      id={field.name}
                      {...field}
                      className={classNames({ "p-invalid": fieldState.error })}
                      onChange={(e) => field.onChange(e.target.value)}
                    />
                  </span>
                  {getFormErrorMessage(field.name)}
                </>
              )}
            />
            <Controller
              name="password"
              control={control}
              rules={{ required: "Password is required." }}
              render={({ field, fieldState }) => (
                <>
                  <div className="password-header-warpper">
                    <label
                      htmlFor={field.name}
                      className="header-input-text main-darker-01-color"
                    >
                      Password
                    </label>

                    <label
                      className="forgot-password main-darker-02-color"
                      onClick={() => handleNavClick("/forget")}
                    >
                      Forgot Password
                    </label>
                  </div>
                  <Password
                    id={field.name}
                    {...field}
                    inputRef={field.ref}
                    className={classNames({ "p-invalid": fieldState.error })}
                    feedback={false}
                    toggleMask
                  />
                  {getFormErrorMessage(field.name)}
                </>
              )}
            />
            <div className="checkbox">
              <Checkbox
                inputId="keepMeSignedIN"
                onChange={(e) => setChecked(e.checked)}
                checked={checked}
              />
              <label htmlFor="keepMeSignedIN" className="ml-2">
                Keep me signed in
              </label>
            </div>

            <Button label="Log In" type="submit" className="login-btn" />
          </form>

          <div className="social-login-btn">
            <Button outlined className="main-darker-02-color line-bg-box-bg">
              <img alt="logo" src={google_color_image} className="h-2rem"></img>
              <label>Log In With Google</label>
            </Button>
            <Button outlined className="main-darker-02-color line-bg-box-bg ">
              <img
                alt="logo"
                src={facebook_color_image}
                className="h-2rem"
              ></img>
              <label>Log In With Facebook</label>
            </Button>
          </div>
        </div>
        <div className="sign-up-wrapper">
          <div className="divider">
            <Divider align="center"></Divider>
            <span className="divider-text">or sign up with</span>
            <Divider align="center"></Divider>
          </div>
          <div className="sign-up-btn-group-wrapper">
            <Button outlined className="main-darker-02-color line-bg-box-bg">
              <img alt="logo" src={google_dark_image} className="h-2rem"></img>
              <label>Google</label>
            </Button>
            <Button outlined className="main-darker-02-color line-bg-box-bg">
              <img
                alt="logo"
                src={facebook_dark_image}
                className="h-2rem"
              ></img>
              <label>Facebook</label>
            </Button>
          </div>
        </div>
        <span className="sigup-text-wrapper neutral-color">
          Don’t have an Account?{" "}
          <span
            className="sing-up-link orange-color"
            onClick={() => handleNavClick("/register")}
          >
            Sign up here
          </span>
        </span>
      </div>
    </div>
  );
};

Login.propTypes = {};

Login.defaultProps = {};
