import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./Header.scss";
import { BreadCrumb } from "primereact/breadcrumb";
import { Avatar } from "primereact/avatar";

// Mock Card Data
import cardData from "../../../services/card.data.json";
import subCardData from "../../../services/sub-card-data.json";

import { v4 as uuidv4 } from "uuid";
import { ShareDialog } from "../share-dialog/ShareDialog";
const init_state = {
  breadCrumb: [
    {
      label: "Projects",
      url: "/pimahome",
      command: (e) => {},
    },
  ],
};

export const Header = ({ filteredCards, hasBack, handleBackToFolder }) => {
  const [items, setItems] = useState(init_state.breadCrumb);
  const [card, setCard] = useState({});
  const [visibleShareDialog, setVisibleShareDialog] = useState(false);
  const home = {
    label: "PiMa",
    url: "/pimahome",
    command: (e) => {},
  };

  useEffect(() => {
    const url = window.location.pathname;
    const parts = url.split("/");
    const idParam = parts[parts.indexOf("pimahome") + 1];
    if (idParam) {
      let card = getObjectById(idParam);
      setCard(card);
      if (card.owner) {
        setItems([
          ...init_state.breadCrumb,
          { label: card.owner.name, url: card.owner.id },
          { label: card?.name },
        ]);
      } else {
        setItems(() => [...init_state.breadCrumb, { label: card?.name }]);
      }
    } else {
      setCard({});
      setItems(() => [...init_state.breadCrumb]);
    }
  }, [filteredCards]);

  const getObjectById = (id) => {
    let tmpOutput;
    tmpOutput = cardData.find((item) => item.id === id) || null;

    if (tmpOutput === null) {
      tmpOutput = subCardData.find((item) => item.id === id) || null;
    }

    return tmpOutput;
  };

  const onBackClick = () => {
    handleBackToFolder(items);
  };

  return (
    <div className="header-wrapper">
      {hasBack ? (
        <i className="pi pi-arrow-left back-arrow" onClick={onBackClick}></i>
      ) : (
        <></>
      )}
      <div className="relative">
        <BreadCrumb
          className="main-darker-01-color"
          model={items}
          home={home}
        />
        {(card?.isSharing === true || card?.owner?.isSharing === true) && (
          <Avatar
            className="sharing-btn"
            icon="pi pi-users"
            size="large"
            shape="circle"
            style={{ backgroundColor: "transparent" }}
            onClick={() => setVisibleShareDialog(true)}
          />
        )}
      </div>

      <div className="header-text main-darker-01-color">
        <span>{card.name ? card.name : "Project"}</span>
      </div>

      <ShareDialog
        card={card}
        setVisibleShareDialog={setVisibleShareDialog}
        visibleShareDialog={visibleShareDialog}
      />
    </div>
  );
};

Header.propTypes = {};

Header.defaultProps = {};
