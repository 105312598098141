import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./ShareDialog.scss";
import { Avatar } from "primereact/avatar";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import { Toast } from "primereact/toast";
import { Chips } from "primereact/chips";
import { v4 as uuidv4 } from "uuid";

export const ShareDialog = ({
  card,
  setVisibleShareDialog,
  visibleShareDialog,
}) => {
  const [tmpCard, setTmpCard] = useState();
  const [chipsValue, setChipsValue] = useState([]);
  const [loadingShare, setLoadingShare] = useState(false);
  const toast = useRef(null);

  useEffect(() => {
    setTmpCard(card);
  }, []);

  useEffect(() => {
    setTmpCard(card);
  }, [card]);

  const footerContent = (
    <div>
      <Button
        className="finish-btn"
        label="Finish"
        onClick={() => setVisibleShareDialog(false)}
      />
    </div>
  );

  const cardSharingContent = (item) => {
    return (
      <div key={uuidv4()}>
        <div className="card-user-sharing">
          <div className="sharing-card-header">
            <Avatar
              label={item.email.charAt(0).toUpperCase()}
              size="large"
              shape="circle"
            />
            {item.email}
          </div>
          <div>
            <Avatar
              className="sharing-remove-btn"
              icon="pi pi-trash"
              size="large"
              shape="circle"
              style={{ backgroundColor: "transparent" }}
              onClick={(event) => comfirmDeleteUserSharing(event, item.email)}
            />
          </div>
        </div>
      </div>
    );
  };

  const comfirmDeleteUserSharing = (event, email) => {
    confirmPopup({
      target: event.currentTarget,
      message: `Do you want to delete the user '${email}'?`,
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      acceptLabel: "Yes",
      accept: () => {
        if (tmpCard.owner) {
          const updatedOwnerSharing = tmpCard.owner.sharing.filter(
            (item) => item.email !== email
          );
          setTmpCard((prevTmpCard) => ({
            ...prevTmpCard,
            owner: { ...prevTmpCard.owner, sharing: updatedOwnerSharing },
          }));
        } else {
          const updatedSharing = tmpCard.sharing.filter(
            (item) => item.email !== email
          );
          setTmpCard((prevTmpCard) => ({
            ...prevTmpCard,
            sharing: updatedSharing,
          }));
        }
        success(`User '${email}' deleted`);
      },
      rejectLabel: "No",
      reject: () => {
        reject("Canceled delete user");
      },
    });
  };

  const accept = (text) => {
    toast.current.show({
      severity: "info",
      summary: "Confirmed",
      detail: text,
      life: 3000,
    });
  };

  const success = (text) => {
    toast.current.show({
      severity: "success",
      summary: "Success",
      detail: text,
      life: 3000,
    });
  };

  const reject = (text) => {
    toast.current.show({
      severity: "warn",
      summary: "Rejected",
      detail: text,
      life: 3000,
    });
  };

  // Custom Chip
  const customChip = (item) => {
    return (
      <div className="chips-wrapper" key={uuidv4()}>
        <Avatar
          label={item.charAt(0).toUpperCase()}
          shape="circle"
          style={{ backgroundColor: "#2196F3", color: "#ffffff" }}
        />
        <span>{item}</span>
      </div>
    );
  };

  const handleClickShare = () => {
    setLoadingShare(true);

    const uniqueEmails = new Set();

    chipsValue.forEach((email) => {
      uniqueEmails.add(email);
    });

    const uniqueEmailArray = Array.from(uniqueEmails);

    if (tmpCard.owner) {
      uniqueEmailArray.forEach((email) => {
        if (!tmpCard.owner.sharing.some((item) => item.email === email)) {
          tmpCard.owner.sharing.push({ email });
          success(`Share to ${email} success`);
        } else {
          reject(`Duplicate email '${email}'`);
        }
      });
    } else {
      uniqueEmailArray.forEach((email) => {
        if (!tmpCard.sharing.some((item) => item.email === email)) {
          tmpCard.sharing.push({ email });
          success(`Share to ${email} success`);
        } else {
          reject(`Duplicate email '${email}'`);
        }
      });
    }

    setTimeout(() => {
      setLoadingShare(false);
      setChipsValue([]);
    }, 1000);
  };

  return (
    <>
      <Toast ref={toast} />
      <Dialog
        className="dialog-wrapper"
        header={`Share "${
          tmpCard?.owner ? tmpCard?.owner.name : tmpCard?.name
        }"`}
        visible={visibleShareDialog}
        style={{ width: "500px" }}
        breakpoints={{ "960px": "50vw", "641px": "75vw" }}
        onHide={() => setVisibleShareDialog(false)}
        footer={footerContent}
      >
        <div className="card p-fluid" key={uuidv4()}>
          <Chips
            value={chipsValue}
            onChange={(e) => setChipsValue(e.value)}
            itemTemplate={customChip}
          />
        </div>
        {chipsValue.length > 0 && (
          <Button
            className="sharing-add-user-btn"
            label="Sharing"
            loading={loadingShare}
            onClick={handleClickShare}
          />
        )}

        <div className="share-modal .main-darker-01-color">
          <div className="header-user-sharing">User sharing</div>
          {tmpCard?.sharing?.map((item, i) => {
            return cardSharingContent(item);
          })}
          {tmpCard?.owner?.sharing?.map((item, i) => {
            return cardSharingContent(item);
          })}
        </div>
        <ConfirmPopup />
      </Dialog>
    </>
  );
};

ShareDialog.propTypes = {};

ShareDialog.defaultProps = {};
