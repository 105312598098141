import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import "./Register.scss";
import { Avatar } from "primereact/avatar";
import { Controller, useForm } from "react-hook-form";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";

export const Register = ({}) => {
  const navigate = useNavigate();
  const toast = useRef(null);

  const show = () => {
    toast.current.show({
      severity: "success",
      summary: "Register Success",
      // detail: getValues("email") + getValues("password"),
    });
  };

  const defaultValues = {
    name: "",
    surname: "",
    email: "",
    password: "",
    confirmPassword: "",
    company: "",
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    getValues,
    reset,
  } = useForm({ defaultValues });

  const [loading, setLoading] = useState(false);
  const onSubmit = (data) => {
    setLoading(true);

    const allFieldsFilled = Object.values(data).every((value) => !!value);

    if (allFieldsFilled) {
      if (data.password === data.confirmPassword) {
        console.log(data);
        show();

        setTimeout(() => {
          setLoading(false);
          navigate("/");
          reset();
        }, 2000);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Passwords do not match.",
        });
      }
    } else {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "All fields must be filled.",
      });
    }
  };

  const getFormErrorMessage = (name) => {
    return errors[name] ? (
      <small className="p-error">{errors[name].message}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  const handleBackClick = () => {
    navigate("/");
  };

  return (
    <div className="register-container" key={"register"}>
      <div className="register-wrapper">
        <div className="logo-wrapper">
          <Avatar icon="pi pi-box" size="xlarge" shape="circle" />
          <span className="header-text main-darker-01-color">Register</span>
        </div>
        <div className="input-wrapper">
          <form onSubmit={handleSubmit(onSubmit)} className="flex flex-column ">
            <Toast ref={toast} />
            <Controller
              name="name"
              control={control}
              rules={{ required: "Name is required." }}
              render={({ field, fieldState }) => (
                <>
                  <label
                    htmlFor={field.name}
                    className="header-input-text main-darker-01-color"
                  >
                    Name
                  </label>
                  <InputText
                    id={field.name}
                    {...field}
                    className={classNames({ "p-invalid": fieldState.error })}
                    onChange={(e) => field.onChange(e.target.value)}
                  />
                  {getFormErrorMessage(field.name)}
                </>
              )}
            />
            <Controller
              name="surname"
              control={control}
              rules={{ required: "Surname is required." }}
              render={({ field, fieldState }) => (
                <>
                  <label
                    htmlFor={field.name}
                    className="header-input-text main-darker-01-color"
                  >
                    Surname
                  </label>

                  <InputText
                    id={field.name}
                    {...field}
                    className={classNames({ "p-invalid": fieldState.error })}
                    onChange={(e) => field.onChange(e.target.value)}
                  />

                  {getFormErrorMessage(field.name)}
                </>
              )}
            />
            <Controller
              name="email"
              control={control}
              rules={{ required: "Email is required." }}
              render={({ field, fieldState }) => (
                <>
                  <label
                    htmlFor={field.name}
                    className="header-input-text main-darker-01-color"
                  >
                    Email Address
                  </label>

                  <InputText
                    id={field.name}
                    {...field}
                    className={classNames({ "p-invalid": fieldState.error })}
                    onChange={(e) => field.onChange(e.target.value)}
                  />

                  {getFormErrorMessage(field.name)}
                </>
              )}
            />
            <Controller
              name="password"
              control={control}
              rules={{ required: "Password is required." }}
              render={({ field, fieldState }) => (
                <>
                  <div className="password-header-warpper">
                    <label
                      htmlFor={field.name}
                      className="header-input-text main-darker-01-color"
                    >
                      Password
                    </label>
                  </div>
                  <Password
                    id={field.name}
                    {...field}
                    inputRef={field.ref}
                    className={classNames({ "p-invalid": fieldState.error })}
                    feedback={false}
                    toggleMask
                  />
                  {getFormErrorMessage(field.name)}
                </>
              )}
            />
            <Controller
              name="confirmPassword"
              control={control}
              rules={{
                required: "Confirm Password is required.",
                validate: (value) =>
                  value === getValues("password") || "Passwords do not match.",
              }}
              render={({ field, fieldState }) => (
                <>
                  <label
                    htmlFor={field.name}
                    className="header-input-text main-darker-01-color"
                  >
                    Confirm Password
                  </label>
                  <Password
                    id={field.name}
                    {...field}
                    inputRef={field.ref}
                    className={classNames({ "p-invalid": fieldState.error })}
                    feedback={false}
                    toggleMask
                  />
                  {getFormErrorMessage(field.name)}
                </>
              )}
            />
            <Controller
              name="company"
              control={control}
              rules={{ required: "Company is required." }}
              render={({ field, fieldState }) => (
                <>
                  <label
                    htmlFor={field.name}
                    className="header-input-text main-darker-01-color"
                  >
                    Company
                  </label>
                  <InputText
                    id={field.name}
                    {...field}
                    className={classNames({ "p-invalid": fieldState.error })}
                    onChange={(e) => field.onChange(e.target.value)}
                  />
                  {getFormErrorMessage(field.name)}
                </>
              )}
            />
            <Button
              label="Register"
              type="submit"
              className="register-btn"
              loading={loading}
            />
          </form>
          <Button
            label="Back"
            className="back-btn"
            outlined
            onClick={handleBackClick}
          />
        </div>
      </div>
    </div>
  );
};

Register.propTypes = {};

Register.defaultProps = {};
