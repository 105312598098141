import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

import "./Card.scss";
import { CardContent } from "./CardContent";

export const Card = ({
  card,
  id,
  index,
  onSelectionChange,
  isSelected,
  onFavoriteClick,
  onOtherClick,
  isList,
  setActiveIndex,
  galleria,
}) => {
  const cardClassName = `card-image  ${isList ? "list" : "folder"} ${
    isSelected ? "selected" : ""
  }`;

  const onClick = (e) => {
    onSelectionChange(e, id);
  };

  const styleClasses = [];

  if (isSelected) {
    styleClasses.push("card-wrapper-selected");
  }

  const handleDoubleClick = () => {
    console.log("DOUBLE CLICK IMAGE");
    setActiveIndex(index);
    galleria.current.show();
  };

  return (
    <>
      {isList === true && (
        <div
          onClick={onClick}
          style={{ position: "relative" }}
          className={cardClassName}
          key={"card-div-" + id}
        >
          <CardContent
            isList={isList}
            card={card}
            onFavoriteClick={onFavoriteClick}
            onOtherClick={onOtherClick}
          />
        </div>
      )}
      {isList === false && (
        <div
          onClick={onClick}
          style={{ position: "relative" }}
          onDoubleClick={handleDoubleClick}
          className={cardClassName}
          key={"card-div-" + id}
        >
          <CardContent
            isList={isList}
            card={card}
            onFavoriteClick={onFavoriteClick}
            onOtherClick={onOtherClick}
          />
        </div>
      )}
    </>
  );
};

Card.propTypes = {
  onSelectionChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  isSelected: PropTypes.bool.isRequired,
};
