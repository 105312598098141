import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import "./PimaHome.scss";
import { Header } from "../../components/home/header/Header";
import { SearchBar } from "../../components/home/seach-bar/SearchBar";
import { ActionButtonGroup } from "../../components/home/action-button-group/ActionButtonGroup";
import { Outlet } from "react-router-dom";
import { Avatar } from "primereact/avatar";

// Mock Card Data
import cardData from "../../services/card.data.json";
import subCardData from "../../services/sub-card-data.json";

import { v4 as uuidv4 } from "uuid";
import { TopBar } from "../../components/top-bar/Topbar";
const init_state = {
  cards: cardData,
  selectedCards: [],
  lastSelectedIndex: -1,
  dragIndex: -1,
};

export const PimaHome = ({}) => {
  // console.log(uuidv4());
  const navigate = useNavigate();
  const [cards, setCards] = useState(init_state.cards);
  const [type, setType] = useState();
  const [selectedCards, setSelectedCards] = useState(init_state.selectedCards);
  const [lastSelectedIndex, setLastSelectedIndex] = useState(
    init_state.lastSelectedIndex
  );
  const [isList, setIsList] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [hasBack, setHasBack] = useState(false);
  const filteredCards = cards?.filter((card) =>
    card?.name?.toLowerCase().includes(searchText.toLowerCase())
  );
  const [backArrowClicked, setBackArrowClicked] = useState(false);
  const location = useLocation();

  const addProject = (newProject) => {
    setCards([...cards, newProject.data]);
  };

  useEffect(() => {
    console.log(cards);
  }, [cards]);

  const handleOtherClick = (card, text) => {
    console.log(`Card ${card.name} was ${text}`);
    switch (text) {
      case "download":
        break;
      case "sysn":
        break;
      case "remove":
        handleDeleteSelected();
        break;

      default:
        break;
    }
  };

  const handleDownload = () => {
    console.log(selectedCards);
    console.log("WAS DOWNLOAD");
  };

  const handleFavoriteClick = (selectedCard) => {
    console.log("handleFavoriteClick");
    setCards((prevCards) =>
      prevCards.map((card) => {
        if (card.id === selectedCard.id) {
          return {
            ...card,
            isFavorite: !card.isFavorite,
          };
        }
        return card;
      })
    );

    console.log(
      `Card ${
        selectedCard.name
      } clicked and Favorite is ${!selectedCard.isFavorite}`
    );
  };

  const handleFavoriteClickManyCard = () => {
    if (selectedCards.length > 1) {
      const isFavoriteValue = selectedCards.some((card) => !card.isFavorite);
      console.log(isFavoriteValue);
      setCards((prevCards) =>
        prevCards.map((card) => {
          if (selectedCards.includes(card)) {
            return {
              ...card,
              isFavorite: isFavoriteValue,
            };
          }
          return card;
        })
      );
      clearItemSelection();
    }
  };

  const handleSysn = () => {
    console.log(selectedCards);
    console.log("WAS Sysn");
  };

  const handleDeleteSelected = () => {
    console.log("handleDeleteSelected");
    const updatedCards = cards.filter((card) => !selectedCards.includes(card));
    setCards(updatedCards);
    clearItemSelection();
  };

  const handleSearchChange = (text) => {
    setSearchText(text);
  };

  const clearItemSelection = () => {
    setSelectedCards([]);
    setLastSelectedIndex(-1);
  };

  const handleItemSelection = (event, cardId) => {
    const selectedCardIndex = cards.findIndex((card) => card.id === cardId);
    let newSelectedCards;
    const card = selectedCardIndex < 0 ? "" : cards[selectedCardIndex];
    const newLastSelectedIndex = selectedCardIndex;

    if (!event.cmdKey && !event.shiftKey && !event.ctrlKey) {
      newSelectedCards = [card];
    } else if (event.shiftKey) {
      if (lastSelectedIndex >= selectedCardIndex) {
        newSelectedCards = [].concat.apply(
          selectedCards,
          cards.slice(selectedCardIndex, lastSelectedIndex)
        );
      } else {
        newSelectedCards = [].concat.apply(
          selectedCards,
          cards.slice(lastSelectedIndex + 1, selectedCardIndex + 1)
        );
      }
    } else if (event.cmdKey || event.ctrlKey) {
      const foundIndex = selectedCards.findIndex((f) => f === card);
      console.log(foundIndex);
      // If found, remove it to unselect it.
      if (foundIndex >= 0) {
        newSelectedCards = [
          ...selectedCards.slice(0, foundIndex),
          ...selectedCards.slice(foundIndex + 1),
        ];
      } else {
        newSelectedCards = [...selectedCards, card];
      }
    }
    const finalList = cards
      ? cards.filter((f) => newSelectedCards.find((a) => a === f))
      : [];
    setSelectedCards(finalList);
    setLastSelectedIndex(newLastSelectedIndex);
  };

  const handleDoubleClick = (card) => {
    let cardSelected = getObjectById(card.id);
    setSearchText("");
    setCards(cardSelected.subFolder);
    if (cardSelected.subFolder) {
      if (cardSelected?.subFolder[0]?.type === "image") {
        setType("image");
      } else {
        setType("sub");
      }
    }
    setHasBack(true);
    navigate("/pimahome/" + card.id);
  };

  const handleBackToFolder = (items) => {
    console.log(items);
    setType("image");
    setSelectedCards([]);
    if (items.length === 2) {
      setSearchText("");
      setHasBack(false);
      setCards(init_state.cards);
      setType("project");
      navigate("/pimahome");
    } else if (items.length === 3) {
      setSearchText("");
      let cardSelected = getObjectById(items[1].url);
      setCards(cardSelected.subFolder);
      navigate(items[1].url);
      setType("sub");
    }
  };

  const getObjectById = (id) => {
    let tmpOutput;
    tmpOutput = cardData.find((item) => item.id === id) || null;

    if (tmpOutput === null) {
      tmpOutput = subCardData.find((item) => item.id === id) || null;
    }

    return tmpOutput;
  };

  const contextValue = {
    isList,
    filteredCards,
    selectedCards,
    handleItemSelection,
    clearItemSelection,
    handleFavoriteClick,
    handleOtherClick,
    handleDoubleClick,
  };

  useEffect(() => {
    onHandleUseEffect();
  }, []);

  // Handle user click browser back arrow
  useEffect(() => {
    const handlePopState = (event) => {
      if (location.pathname !== event.state?.pathname) {
        setBackArrowClicked(true);
      }
    };
    window.addEventListener("popstate", handlePopState);
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [location.pathname]);

  useEffect(() => {
    if (backArrowClicked) {
      onHandleUseEffect();
      setBackArrowClicked(false);
    }
  }, [backArrowClicked]);

  const onHandleUseEffect = () => {
    const url = window.location.pathname;
    const parts = url.split("/");
    const idParam = parts[parts.indexOf("pimahome") + 1];
    if (idParam) {
      let cardSelected = getObjectById(idParam);
      setCards(cardSelected.subFolder);
      if (cardSelected.subFolder) {
        if (cardSelected.subFolder[0].type === "image") {
          setType("image");
        } else {
          setType("sub");
        }
      } else {
        setType("sub");
      }

      setHasBack(true);
    } else {
      setCards(init_state.cards);
      setType("project");
    }
  };

  return (
    <div className="home-container" key={"pima-home"}>
      <TopBar />
      <div className="content-wrapper">
        <Header
          filteredCards={filteredCards}
          hasBack={hasBack}
          handleBackToFolder={handleBackToFolder}
        ></Header>
        <SearchBar
          text={searchText}
          onSearchChange={handleSearchChange}
          setIsList={setIsList}
        ></SearchBar>
        <ActionButtonGroup
          selectedCards={selectedCards}
          onDeSelected={clearItemSelection}
          onDownload={handleDownload}
          onFavorite={handleFavoriteClickManyCard}
          onSysn={handleSysn}
          onDeleteSelected={handleDeleteSelected}
          type={type}
          filteredCards={filteredCards}
          hasBack={hasBack}
          setNewProject={addProject}
        />
        <Outlet context={contextValue} />
      </div>
    </div>
  );
};

PimaHome.propTypes = {};

PimaHome.defaultProps = {};
