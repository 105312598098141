import React from "react";
import { useOutletContext } from "react-router-dom";
import PropTypes from "prop-types";
import { Card } from "../../../components/home/content/project/Card";
import "./Project.scss";
export const Project = ({}) => {
  let filteredCards = useOutletContext().filteredCards;
  let isList = useOutletContext().isList;
  let selectedCards = useOutletContext().selectedCards;
  let handleItemSelection = useOutletContext().handleItemSelection;
  let clearItemSelection = useOutletContext().clearItemSelection;
  let handleFavoriteClick = useOutletContext().handleFavoriteClick;
  let handleOtherClick = useOutletContext().handleOtherClick;
  let handleDoubleClick = useOutletContext().handleDoubleClick;

  return (
    <div
      className={["folder-container", isList ? "list" : ""].join(" ")}
      key={"project"}
    >
      <div className={["cards-wrapper", isList ? "list" : ""].join(" ")}>
        {filteredCards?.map((card, i) => {
          return (
            <Card
              card={card}
              key={"card-" + card.id}
              id={card.id}
              index={i}
              selectedCards={selectedCards}
              onSelectionChange={handleItemSelection}
              clearItemSelection={clearItemSelection}
              isSelected={selectedCards.includes(card)}
              isList={isList}
              onFavoriteClick={handleFavoriteClick}
              onOtherClick={handleOtherClick}
              onDoubleClick={handleDoubleClick}
            />
          );
        })}
      </div>
    </div>
  );
};

Project.propTypes = {};

Project.defaultProps = {};
