import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./ActionButtonGroup.scss";
import { Button } from "primereact/button";
import { Avatar } from "primereact/avatar";
import { Dialog } from "primereact/dialog";
import { Controller, useForm } from "react-hook-form";
import { classNames } from "primereact/utils";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { Menu } from "primereact/menu";

import close_image from "../../../assets/icon/close_01.svg";
import download_image from "../../../assets/icon/download_01.svg";
import sync_image from "../../../assets/icon/sync_01.svg";
import trash_image from "../../../assets/icon/trash_01.svg";
import other_01_image from "../../../assets/icon/other_01.svg";

// Mock Card Data
import cardData from "../../../services/card.data.json";
import subCardData from "../../../services/sub-card-data.json";
import { v4 as uuidv4 } from "uuid";

export const ActionButtonGroup = ({
  selectedCards,
  onDeleteSelected,
  onFavorite,
  onDeSelected,
  onDownload,
  onSysn,
  type,
  filteredCards,
  hasBack,
  setNewProject,
}) => {
  const [visibleShareDialog, setVisibleShareDialog] = useState();
  const [card, setCard] = useState();
  const toast = useRef(null);
  const menuRight = useRef(null);

  useEffect(() => {
    const url = window.location.pathname;
    const parts = url.split("/");
    const idParam = parts[parts.indexOf("pimahome") + 1];
    if (idParam) {
      let card = getObjectById(idParam);
      setCard(card);
    }
  }, [filteredCards]);

  useEffect(() => {
    if (type === "project") {
      setCard({});
    }
  }, [hasBack]);

  let items = [
    {
      label: "Download",
      icon: "pi pi-fw pi-download",
      command: (e) => {
        onDownload();
      },
    },
    {
      label: "Mark favorite",
      icon: "pi pi-fw pi-star",
      command: (e) => {
        onFavorite();
      },
    },
    {
      label: "Sysn to cloud",
      icon: "pi pi-fw pi-cloud-upload",
      command: (e) => {
        onSysn();
      },
    },
    {
      label: "Move to trash",
      icon: "pi pi-fw pi-trash",
      command: (e) => {
        onDeleteSelected();
      },
    },
  ];

  const getObjectById = (id) => {
    let tmpOutput;
    tmpOutput = cardData.find((item) => item.id === id) || null;

    if (tmpOutput === null) {
      tmpOutput = subCardData.find((item) => item.id === id) || null;
    }

    return tmpOutput;
  };

  const show = () => {
    let text =
      type === "project"
        ? "Create New Project Success"
        : "Create New Sub Project Success";
    toast.current.show({
      severity: "success",
      summary: text,
      detail: `Project name : ${getValues("projectName")}`,
    });
  };

  const defaultNewProjectValue = {
    projectName: "",
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    getValues,
    reset,
  } = useForm({ defaultNewProjectValue });

  const onSubmitCreateNewProject = (data) => {
    if (data.projectName) {
      if (type === "project") {
        let tmpData = {
          card: card,
          data: {
            id: uuidv4(),
            name: data.projectName,
            isSharing: false,
            isFavorite: false,
            sharing: [],
            subFolder: [],
          },
        };
        setNewProject(tmpData);
      } else if (type === "sub") {
        let tmpData = {
          card: card,
          data: {
            id: uuidv4(),
            name: data.projectName,
            isFavorite: false,
            type: "folder",
          },
        };
        setNewProject(tmpData);
      }

      show();
    }
    setVisibleShareDialog(false);
    setCard({});
    reset();
  };
  const getFormErrorMessage = (name) => {
    return errors[name] ? (
      <small className="p-error">{errors[name].message}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  const footerContent = (
    <div className="btn-create-project-footer ">
      <Button
        className="cancle-btn"
        label="Cancle"
        onClick={() => {
          setCard({});
          reset();
          setVisibleShareDialog(false);
        }}
        text
      />
      <Button
        className="create-btn"
        label="Create"
        type="submit"
        onClick={handleSubmit(onSubmitCreateNewProject)}
        autoFocus
      />
    </div>
  );

  return (
    <div className="action-button-group-container">
      <Toast ref={toast} />
      <Dialog
        className="dialog-create-project-wrapper"
        header={
          type === "project" ? "Create New Project" : "Create New Sub Project"
        }
        visible={visibleShareDialog}
        style={{ width: "500px" }}
        breakpoints={{ "960px": "50vw", "641px": "75vw" }}
        onHide={() => {
          setCard({});
          reset();
          setVisibleShareDialog(false);
        }}
        footer={footerContent}
      >
        <div>
          <form
            onSubmit={handleSubmit(onSubmitCreateNewProject)}
            className="flex flex-column gap-2"
          >
            <Controller
              name="projectName"
              control={control}
              rules={{
                required:
                  type === "project"
                    ? "Project name is required."
                    : "Sub Project name is required.",
              }}
              render={({ field, fieldState }) => (
                <>
                  <InputText
                    id={field.name}
                    defaultValue={field.value}
                    className={classNames({ "p-invalid": fieldState.error })}
                    onChange={(e) => field.onChange(e.target.value)}
                    placeholder={
                      type === "project" ? "Project Name" : "Sub Project Name"
                    }
                  />

                  {getFormErrorMessage(field.name)}
                </>
              )}
            />
          </form>
        </div>
      </Dialog>

      {type === "project" && (
        <Button
          label="Create New Project"
          type="submit"
          className="create-new-project-btn"
          icon="pi pi-plus"
          onClick={() => setVisibleShareDialog(true)}
        />
      )}
      {type === "sub" && (
        <Button
          label="Create New Sub Project"
          type="submit"
          className="create-new-project-btn"
          icon="pi pi-plus"
          onClick={() => setVisibleShareDialog(true)}
        />
      )}

      {selectedCards?.length > 1 && (
        <>
          <div className="selected-other-group">
            <Button className="selected-btn" text onClick={onDeSelected}>
              <Avatar image={close_image} shape="circle" />
              <label className="dark-brown-color">
                {selectedCards.length} Selected
              </label>
            </Button>

            <div className="more-btn">
              <Menu
                model={items}
                popup
                ref={menuRight}
                id="popup_menu_right"
                popupAlignment="right"
              />
              <img
                src={other_01_image}
                alt=""
                onClick={(event) => menuRight.current.toggle(event)}
              />
            </div>
          </div>

          <div className="action-btn-group-wrapper">
            <Button text onClick={onDownload}>
              <Avatar
                image={download_image}
                shape="circle"
                style={{
                  backgroundColor: "rgba(129, 129, 129, 0.40)",
                }}
              />
              <label className="dark-brown-color">Download</label>
            </Button>
            <Button text onClick={onFavorite}>
              <Avatar
                icon="pi pi-star-fill"
                shape="circle"
                style={{
                  backgroundColor: "rgba(244, 244, 244, 0.40)",
                  color: "#2bace3",
                }}
              />
              <label className="dark-brown-color">Mark favorite</label>
            </Button>
            <Button text onClick={onSysn}>
              <Avatar
                image={sync_image}
                shape="circle"
                style={{
                  backgroundColor: "rgba(244, 244, 244, 0.40)",
                }}
              />
              <label className="dark-brown-color">Sync to cloud</label>
            </Button>
            <Button text onClick={onDeleteSelected}>
              <Avatar
                image={trash_image}
                shape="circle"
                style={{
                  backgroundColor: "rgba(244, 244, 244, 0.40)",
                }}
              />
              <label className="dark-brown-color">Move to trash</label>
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

ActionButtonGroup.propTypes = {};

ActionButtonGroup.defaultProps = {};
