import React from "react";
import "./Topbar.scss";
import { Avatar } from "primereact/avatar";

import other_01_image from "../../assets/icon/other_01.svg";
import user_circle_01 from "../../assets/icon/user_circle_01.svg";

export const TopBar = ({ user }) => {
  return (
    <div className="top-bar-container">
      <div className="logo-wrapper">
        <Avatar icon="pi pi-box" size="xlarge" shape="circle" />
        <span className="header-text main-darker-02-color">
          P<span className="main-orange-01-color">i</span>Ma
        </span>
      </div>
      <div className="user-info-wrapper">
        <div className="inner-user-info">
          <span>{user?.email}</span>
          <img src={user_circle_01} alt="" />
          <img src={other_01_image} alt="" />
        </div>
      </div>
    </div>
  );
};
