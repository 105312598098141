import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { ShareDialog } from "../../share-dialog/ShareDialog";

import "./Card.scss";
import { CardContent } from "./CardContent";
export const Card = ({
  card,
  id,
  onSelectionChange,
  isSelected,
  onDoubleClick,
  onFavoriteClick,
  onOtherClick,
  isList,
}) => {
  const [visibleShareDialog, setVisibleShareDialog] = useState(false);

  const cardClassName = `card-folder   ${isList ? "list" : "folder"} ${
    isSelected ? "selected" : ""
  }`;

  const onClick = (e) => {
    onSelectionChange(e, id);
  };

  const styleClasses = [];

  if (isSelected) {
    styleClasses.push("card-wrapper-selected");
  }

  const handleDoubleClick = () => {
    onDoubleClick(card);
  };

  return (
    <>
      {isList === true && (
        <div
          onClick={onClick}
          style={{ position: "relative" }}
          onDoubleClick={handleDoubleClick}
          className={cardClassName}
          key={"card-div-" + id}
        >
          <CardContent
            card={card}
            onFavoriteClick={onFavoriteClick}
            onOtherClick={onOtherClick}
            setVisibleShareDialog={setVisibleShareDialog}
          />
        </div>
      )}
      {isList === false && (
        <div
          onClick={onClick}
          style={{ position: "relative" }}
          onDoubleClick={handleDoubleClick}
          className={cardClassName}
          key={"card-div-" + id}
        >
          <CardContent
            card={card}
            onFavoriteClick={onFavoriteClick}
            onOtherClick={onOtherClick}
            setVisibleShareDialog={setVisibleShareDialog}
          />
        </div>
      )}

      <ShareDialog
        card={card}
        setVisibleShareDialog={setVisibleShareDialog}
        visibleShareDialog={visibleShareDialog}
      />
    </>
  );
};

Card.propTypes = {
  onSelectionChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  isSelected: PropTypes.bool.isRequired,
};
